// pub/sub for multiple consecutive calls with same callback
// assumes in all functions that if no context is passed, it uses undefined,
// which falls back to the global object
// (more at: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/apply#Parameters)

class TopicStruct {
  constructor(topic) {
    this.topic = topic;
    this.calls_queue = [];
    this.subscriptions_queue = [];
  }

  get queue() {
    return this.calls_queue;
  }

  get subscriptions() {
    return this.subscriptions_queue;
  }

  fireQueue(callback, context) {
    this.calls_queue.forEach((call) => {
      callback.apply(context, call.args);
    });

    // removal needs to happen after apply
    // otherwise, forEach skips elements
    this.flushQueueCall();
  }

  flushQueueCall() {
    this.calls_queue = [];

    return this.calls_queue;
  }

  fireSubscriptions(args) {
    this.subscriptions_queue.forEach(subscription => subscription[0].apply(subscription[1], args));
  }

  flushSubscriptions() {
    this.subscriptions_queue = [];

    return this.subscriptions_queue;
  }

  addSuscription(callback, context) {
    this.subscriptions_queue.push([callback, context]);
  }

  removeSuscription(callback) {
    this.subscriptions_queue.forEach((suscription, idx) => {
      if (callback === suscription[0]) {
        this.subscriptions_queue.splice(idx, 1);
      }
    });
  }
}

const topics = {
  _get(topic) {
    return this[topic] || (this[topic] = new TopicStruct(topic));
  },
};

function pub(topic) {
  const args = [].slice.call(arguments, 1);
  const topicInstance = topics._get(topic);

  if (topicInstance.subscriptions.length) {
    topicInstance.fireSubscriptions(args);
  } else {
    topicInstance.queue.push({ args });
  }
}
function sub(topic, callback, context) {
  const topicInstance = topics._get(topic);

  topicInstance.addSuscription(callback, context);

  if (topicInstance.queue.length) {
    topicInstance.fireQueue(callback, context);
  }
}
function unsub(topic, callback) {
  const topicInstance = topics._get(topic);

  if (callback) {
    topicInstance.removeSuscription(callback);
  } else {
    topicInstance.flushSubscriptions();
    topics[topic] = null;
    delete topics[topic];
  }
}

module.exports = {
  init(object) {
    object.pub = pub;
    object.sub = sub;
    object.unsub = unsub;
  },
};
