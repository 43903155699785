// Bootstrap
// ===================
// General utility file.

const messaging = require('common/messaging');

if (BC.getCookie('BC_USR')) {
  BC.user = {
    isExplicitOrAutoLoggedIn: BC.getProfileCookie('BC_USR', 'explicitOrAutoLoggedIn') || false,
    isLoggedIn: BC.getProfileCookie('BC_USR', 'loggedIn') || false,
    isRegistered: BC.getProfileCookie('BC_USR', 'registered') || false,
  };
  BC.profileCookie = {
    id: BC.getProfileCookie('BC_USR', 'id') || '',
    displayName: BC.getProfileCookie('BC_USR', 'displayName').replace(/\+/g, ' ') || '',
    imagePath: BC.getProfileCookie('BC_USR', 'imagePath').replace(/\+/g, ' ') || '',
    login: BC.getProfileCookie('BC_USR', 'login') || '',
  };
  BC.profile = BC.profile || {};
  $.extend(BC.profile, BC.profileCookie);
}

// Add global pubsub style messaging.
(function() {
  var topics = {
    _get(topic) {
      return this[topic] || (this[topic] = $.Callbacks('memory unique'));
    },
  };

  function publish(topic) {
    var args = [].slice.call(arguments, 1);

    topics._get(topic).fire.apply(this, args);
  }

  function subscribe(topic, callback, context) {
    if (context) {
      callback.proxy = $.proxy(callback, context);
    }
    topics._get(topic).add(callback.proxy || callback);
  }

  function unsubscribe(topic, callback) {
    // TODO there's a bug that doesn't remove the listener when a callback is provided, use BC.sub/pub/unsub alternative
    if (callback) {
      topics._get(topic).remove(callback.proxy || callback);
    } else {
      delete topics[topic];
    }
  }

  $.extend({
    publish,
    subscribe,
    unsubscribe,
  });
  $.extend(BC, {
    publish,
    subscribe,
    unsubscribe,
  });
}());

// init pub/sub. prefer over publish and suscribe

messaging.init(BC);

// Loads 3rd party scripts asynchronously. Supports callback functions
(function() {
  var queue = [];
  var loaded = false;

  // Enqueues an object. Optionally the object can be deferred (until after DOMReady)
  // or executed right away.
  function enqueue(obj) {
    if (obj && typeof obj == 'object') {
      if (!loaded && obj.defer) {
        queue.push(obj);
      } else {
        execute(obj);
      }
    }
  }

  // Dequeues all queued objects. After calling this,
  // newly enqueued objects will be executed right away.
  function load() {
    loaded = true;
    while (queue.length) {
      execute(queue.shift());
    }
  }

  // Executes an object. The object can be a simple function
  // or associated to a DOM element (_iframe_, _img_ or _script_).
  function execute(obj) {
    var id = obj.id;
    var src = obj.src;
    var params = obj.params;
    var callback = typeof obj.callback == 'function' ? obj.callback : function() {};

    // If the object is associated with a DOM element.
    if (id && src) {
      // Make sure we're dealing with selectors.
      if (!/^#/.test(id)) {
        id = '#' + id;
      }

      // Serialize url params.
      if (params) {
        src += (/\?/.test(src) ? '&' : '?') + $.param(params);
      }

      // Use postscribe for objects that require `document.write`.
      if (obj.postscribe) {
        postscribe(id, '<script src="' + src + '"></script>', { done: callback });
      } else {
        // Otherwise find the DOM element and set the `src`.
        var $el = $(id);

        // Scripts won't fire a load event (surprise!).
        // Caveat of this approach is the script element itself will never have the `src` attribute populated
        // (getScript just eval's the response).
        // Another approach could be to create a new script node (`.clone()` won't work), copy over properties from the existing script node
        // create listeners for `onload` and `onreadystatechange` (IE) and trigger the callback manually on success.
        if ($el.is('script')) {
          $.getScript(src)
            .done(callback)
            .fail(function(jqxhr, settings) {
              $.error('Error downloading ' + src + '. Ready state: ' + jqxhr.readyState + ', status: ' + jqxhr.status + ', statusText: ' + jqxhr.statusText + ', settings: ' + settings, 'bootstrap.js');
            });
        } else {
          $el
            .on('load', callback)
            .on('error', $.error)
            .attr('src', src);
        }
      }
    } else {
      // If the object is just a simple function then call it now.
      callback();
    }
  }

  function reset() {
    loaded = false;
  }

  $.asyncLoader = {
    enqueue,
    load,
    reset,
  };

  // Kick off deferred objects on Window Load.
  $(window).load(load);
}());

var log = function(data) {
  var uid = ($ && typeof $.cookie === 'function' ? $.cookie('BC_CSESSION') : BC.generateRandomId(32));
  var logData = {
    sessionId: ($ && typeof $.cookie === 'function' ? $.cookie('JSESSIONID') : ''),
    profileId: (BC && BC.profile) ? BC.profile.id : '',
    orderId: (BC && BC.order && BC.order.attributes) ? BC.order.attributes.orderId : '',
    url: document.URL,
    userAgent: navigator.userAgent,
    uid,
    server: (this.s_sc) ? s_sc.server : '',
    taggingId: (BC && BC.page && BC.page.taggingId) ? BC.page.taggingId : '',
    data: (BC && BC.page && BC.page.id) ? BC.page.id : '',
    breakpoint: (typeof has != 'undefined' ? (!has('medium') ? 's' : (!has('large') ? 'm' : (!has('x-large') ? 'l' : 'xl'))) : ''),
  };

  window.stringify = window.stringify || function(jsonobj) {
    jsonobj.stringify = 'Unable to find custom stringify';

    return (JSON.stringify(jsonobj));
  };

  var errorUrl = typeof has !== 'undefined' && has('js_log_api') ? '/v2/log' : '/error/js';

  $.ajax({
    type: 'POST',
    url: errorUrl + '?uid=' + uid,
    data: stringify($.extend(data, logData)),
  });
};

$.info = function(message) {
  var data = {
    message: message || '',
    type: 'info',
  };

  log(data);
};

// Adds javascript error handler, so any errors are appended to the body for selenium tests.
window.onerror = $.error = function(message, fileName, lineNumber, colNumber, exception) {
  window.errCount = (window.errCount) ? window.errCount + 1 : 1;
  if ((typeof has != 'undefined' && has('dev')) || /^\/tests\//.test(window.location.pathname)) {
    throw new Error(message);
  } else if (window.errCount < 15) {
    var errorData = {
      message: message || '',
      type: 'error',
      fileName: fileName || '',
      lineNumber: lineNumber || '',
      colNumber: colNumber || '',
      stack: (exception) ? exception.stack : '',
    };

    // Scenario where we receive an error as the first parameter. This is being done by some clients.
    if (message instanceof Error) {
      errorData = {
        message: message.message || '',
        type: 'error',
        fileName: message.fileName || fileName || '',
        lineNumber: message.lineNumber || lineNumber || '',
        colNumber: message.columnNumber || colNumber || '',
        stack: message.stack || '',
      };
    }

    // Skipping script error because it does not provide useful information
    if (errorData.message !== 'Script error.') {
      log(errorData);
    }
  }
};

$.warn = function(message) {
  if (has('dev')) {
    // eslint-disable-next-line no-console
    console.warn(message);
  }
};

window.__bootstrap.forEach(function(event) {
  switch (event[0]) {
    case 'publish':
      var args = event[2];

      if (args.length) {
        args.unshift(event[1]);
      } else {
        args = [event[1]];
      }
      $.publish.apply(this, args);
      break;
    case 'subscribe':
      $.subscribe.apply(this, event.slice(1));
      break;
    case 'jqload':
      $(event[1]);
      break;
    case 'error':
      $.error.apply(this, event[1]);
      break;
  }
});

$.publish('bootstrap.loaded');
