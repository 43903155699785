bcOmniTrack = {
  _cache: {},

  config: {
    fullPageTrackMap: {
      'brand-home': '_trackPage_brand_home_full',
      collection: '_trackPage_collection_full',
      'community-explore-post': '_trackPage_explorePost_full',
      'gear-guide': '_trackPage_gearGuide_full',
      home: '_trackPage_home_full',
      pdp: '_trackPage_pdp_full',
      promotion: '_trackPage_promotion_full',
      'search-results': '_trackPage_plp_full',
      'shop-all-brands': '_trackPage_shopAllBrands_full',
      'shop-the-look': '_trackPage_shopTheLook_full',
      'static-content': '_trackPage_static_content_full',
      story: '_trackPage_story_full',
    },
    globalFacetList: [
      'activationDate',
      'attr_recommendeduse',
      'attr_shopby',
      'brand',
      'category',
      'colorFamily',
      'discountPercent',
      'reviewAverage',
      'salePrice',
      'scale',
      'size',
    ],
    pageTrackMap: {
      category: '_trackPage_category',
      collection: '_trackPage_collection',
      pdp: '_trackPage_pdp',
      'search-results': '_trackPage_plp',
    },
    filterQueryMapper: {
      color: 'colorFamily',
      features: 'attr_features',
      recommendeduse: 'attr_recommendeduse',
      gender: 'attr_genderage',
      bikemodels: 'attr_bikemodels',
    },
  },

  init(s_sc, options) {
    var self = this;

    options = options || {};

    if (typeof s_sc === 'undefined') {
      return;
    }

    // this must come first in case of ajax navigation. s_code caches the url
    // in s_sc.pageURL which is used by s_sc.getQueryParam
    if (options.resetPageUrl) {
      s_sc.pageURL = window.location.href;
    }

    if (options.clearVars && $.isFunction(s_sc.clearVars)) {
      s_sc.clearVars();
    }

    if (options.timestamp) {
      self._setTimestamp(s_sc);
    }

    if (options.fullClientTracking) {
      self._fullClientTracking(s_sc);
    }

    self._globalTracking(s_sc);
    self._pageTracking(s_sc);
    self._featureTracking(s_sc);

    return s_sc;
  },

  // set vars for global tracking
  _globalTracking(s_sc) {
    var self = this;

    // do NOT reorganize the following methods
    s_sc.bcGetCookie = BC.getProfileCookie;
    self._setEntryPage(s_sc);
    self._setProfile(s_sc);
    self._setPromos(s_sc);
    self._setMarketingCampaigns(s_sc);
    self._setSeoExperiment(s_sc);
    self._setUsersGearhead(s_sc);

    // marin
    s_sc.eVar19 = s_sc.getQueryParam('mkwid');

    // doubleclick (dfp)
    var gclid = s_sc.getQueryParam('gclid');

    if (gclid) {
      s_sc.eVar42 = gclid;
    }

    // cross site
    var wmc = s_sc.getQueryParam('wmc');

    s_sc.eVar55 = (wmc === 'true') ? 'WM_SAC' : s_sc.getQueryParam('cs_id');

    // flyouts
    s_sc.eVar61 = s_sc.getQueryParam('fl') ? 'true' : 'false';

    // traffic splitter for search platform
    s_sc.eVar75 = s_sc.bcGetCookie('tsGroup');

    self._setEvar63(s_sc);
  },

  _setEvar63(s_sc) {
    if (BC && BC.site && BC.site.code !== 'bcs') {
      s_sc.eVar63 = 'ATG';

      // Temporal fix while steepandcheap app is using wrong omniture suite
      if (window.BC && BC.site && BC.site.code === 'steepcheap' && !BC.isNativeApp) {
        s_sc.eVar63 = 'Desktop ATG';
      }
    }
  },

  // set vars for page specific tracking and/or elements
  _pageTracking(s_sc) {
    var self = this;

    self._executeMappedMethod({
      arg: s_sc,
      key: BC.page.id,
      map: self.config.pageTrackMap,
    });
  },

  // set vars for features or elements that exist across multiple different pages/types
  _featureTracking(s_sc) {
    var self = this;
    var pageType = s_sc.eVar15 && s_sc.eVar15.toLowerCase() || '';

    self._executeMethodOnMatch({
      key: BC.page.id,
      list: [
        'brand-home',
        'category',
        'community-explore-post',
        'product-detail',
        'search-results',
      ],
      method: self._setInstantsearch,
      s_sc,
    });

    self._executeMethodOnMatch({
      key: BC.page.id,
      list: [
        'brand-home',
        'category',
        'home',
        'product-detail',
      ],
      method: self._setRecWidgetImpressions,
      s_sc,
    });

    self._executeMethodOnMatch({
      key: pageType,
      list: ['category on sale', 'home'],
      method: self._setSuggestedItem,
      s_sc,
    });
  },

  // for bc-frontend (kraken) pages
  _fullClientTracking(s_sc) {
    var self = this;

    self._setEvar63(s_sc);
    // check on this. if anyone is using proxy, that means internal traffic, blacklisted on omniture
    s_sc.eVar30 = 'web';

    self._executeMappedMethod({
      arg: s_sc,
      key: BC.page.id,
      map: self.config.fullPageTrackMap,
    });
  },

  // START page tracking methods (_full methods execute when fullClientTracking=true)
  _trackPage_collection() {
    this._addShopTheLookPrevPageEvent('event605', s_sc);
  },

  _trackPage_explorePost_full(s_sc) {
    var pageName = 'Explore Post: ' + BC.page.title;
    var pageType = BC.page.postType + ' page';

    s_sc.pageName = s_sc.eVar36 = pageName;
    s_sc.prop11 = s_sc.eVar15 = pageType;
  },

  _trackPage_gearGuide_full(s_sc) {
    var pageName = 'Gear Guide:' + location.pathname.replace('/', '');

    this._setCommonVariables(pageName, 'Activity Page', 'gearguide', s_sc);

    s_sc.eVar14 = pageName; // leaf by default
    s_sc.eVar50 = 'Retail Channel';
    s_sc.eVar57 = pageName;
    s_sc.eVar59 = pageName; // 3rd level by default
    s_sc.prop13 = 'Activity Page:Gear Guide';
    s_sc.channel = pageName;
  },

  _getPreselectionSkuId(s_sc) {
    return s_sc.getQueryParam('skid') || s_sc.getQueryParam('skidn') || '';
  },

  _trackPage_home_full(s_sc) {
    s_sc.pageName = 'Home';
    s_sc.pageType = ''; // server side parity
    s_sc.channel = 'Home';
    s_sc.eVar15 = 'Home';
    s_sc.eVar36 = 'Home';
    s_sc.eVar50 = 'Retail Channel';
    s_sc.eVar57 = 'Home';
    s_sc.eVar99 = 'cst';
    s_sc.prop11 = 'Home';

    this._addOmnitureEntryPageCookie(s_sc);
  },

  _trackPage_brand_home_full(s_sc) {
    s_sc.pageName = 'Brand:' + BC.brand.displayName;
    s_sc.pageType = 'Category Brand';
    s_sc.channel = 'Brand';
    s_sc.eVar15 = 'Category Brand';
    s_sc.eVar36 = 'Brand:' + BC.brand.displayName;
    s_sc.eVar50 = 'Retail Channel';
    s_sc.eVar64 = 'Category';
    s_sc.prop7 = BC.brand.displayName;
    s_sc.prop11 = 'Category Brand';
    s_sc.prop13 = 'Brand';
    s_sc.prop55 = 'Category';
    s_sc.hier1 = '';

    this._addOmnitureEntryPageCookie(s_sc);
  },

  _trackPage_category(s_sc) {
    var catName = BC.category && BC.category.name || '';
    var hier1 = BC.category && BC.category.hierarchy;
    var hierarchyArray = hier1 && hier1.split(',');
    var omnitureCategory = 'Category Cat';

    s_sc.channel = catName;
    s_sc.eVar15 = omnitureCategory;
    s_sc.eVar36 = omnitureCategory + ':' + catName;
    s_sc.eVar50 = 'Retail Channel';
    s_sc.eVar57 = catName;
    s_sc.eVar64 = 'Category';
    s_sc.hier1 = hier1;
    s_sc.pageName = omnitureCategory + ':' + catName;
    s_sc.prop11 = omnitureCategory;
    s_sc.prop55 = 'Category';

    if (hierarchyArray) {
      var hierarchySize = hierarchyArray.length;
      var taxonomyLevel = 'P1';

      if (hierarchySize > 1) {
        taxonomyLevel = 'C' + (hierarchySize - 1);
      }

      s_sc.prop56 = taxonomyLevel;
      s_sc.eVar65 = taxonomyLevel;
    } else {
      $.error('[Error] Undefined category hierarchy for ' + BC && BC.category && BC.category.id, 'omnitrack');
    }
  },

  _trackPage_collection_full(s_sc) {
    var collection = 'collection';
    var collectionName = BC && BC.collection && BC.collection.name ? ('Collection: ' + BC.collection.name) : '';

    s_sc.channel = collection;
    s_sc.eVar15 = collection;
    s_sc.eVar36 = collectionName;
    s_sc.pageName = collectionName;
    s_sc.prop11 = collection;
  },

  _trackPage_pdp(s_sc) {
    var self = this;

    self._setCommunityEvents(s_sc);
    self._setCompetitiveCyclistPdpWidgets(s_sc);
    self._setRecWidgetEvent(s_sc);

    // click position tracking
    var clickPosition = self._getTrackingInfoClickPosition(s_sc);

    if (clickPosition > 0) {
      // s.products=[Category1];[Product1];[Quantity];[Total Price];[Incrementor];[Merchandising]
      s_sc.products = ';' + s_sc.prop5 + ';;;event90=' + clickPosition + ';';
    } else {
      s_sc.products = ';' + s_sc.prop5 + ';;;;';
    }

    // product tracking information for revenue channel attribution
    s_sc.eVar2 = self._getTrackingInfo(s_sc);
    s_sc.products += 'eVar2=' + s_sc.eVar2;

    if (BC && BC.product && BC.product.isGearheadPick) {
      s_sc.eVar81 = 'GearheadPick';
      s_sc.products += '|evar81=GearheadPick';
    }

    s_sc.eVar21 = self._getPreselectionSkuId(s_sc);
  },

  _trackPage_pdp_full(s_sc) {
    var self = this;

    self._trackPage_pdp(s_sc);
    s_sc.prop7 = BC.product.brand && BC.product.brand.displayName || '';
    s_sc.prop12 = s_sc.prop7 + ' ' + BC.product.displayName;
    s_sc.eVar3 = s_sc.eVar14 = BC.product.category && BC.product.category.displayName || '';
    var pageName = BC.product.displayName;
    var pageType = 'Product Detail';

    if (BC.product.stockStatus) {
      if (BC.product.stockStatus === 'POOS') {
        pageName = 'POOS:' + pageName;
        pageType += ' - Permanently Out of Stock';
      } else if (BC.product.stockStatus === 'TOOS') {
        pageName = 'TOOS:' + pageName;
        pageType += ' - Temporarily Out of Stock';
      } else {
        pageName = 'PDP:' + pageName;
        s_sc.events = s_sc.apl(s_sc.events, 'event28', ',', 1);
        s_sc.events = s_sc.apl(s_sc.events, 'prodView', ',', 1);
        if (BC.base64decode(s_sc.getQueryParam('ti')).indexOf('bswidget') > -1) {
          s_sc.events = s_sc.apl(s_sc.events, 'event99', ',', 1);
        }
      }
    }

    var categoriesHierarchy = BC.product.categoriesHierarchy;

    s_sc.pageName = s_sc.eVar36 = pageName;
    s_sc.pageType = s_sc.prop11 = s_sc.eVar15 = pageType;
    s_sc.channel = categoriesHierarchy && categoriesHierarchy.substring(0, categoriesHierarchy.indexOf(',')) || 'Product';
    s_sc.prop5 = BC.product.id;

    if (BC.product.isOnSale) {
      s_sc.eVar33 = 'Sale Price';
      s_sc.eVar34 = BC.product.discount;
    } else {
      s_sc.eVar33 = 'Regular Price';
    }

    s_sc.eVar99 = 'cst';
  },

  _trackPage_plp(s_sc) {
    this._setClickedFacets(s_sc);
    this._setInstantsearch(s_sc);
    this._setPlpSorting(s_sc);
  },

  _trackPage_plp_full(s_sc) {
    var self = this;
    var displayName = '';
    var hierarchySize = $('.crumb').length - 1;
    var pageName = '';
    var pageNumber = s_sc.getQueryParam('page');
    var products;
    var searchTerm = $('#search-term').text()
      .trim();
    var taxonomyHierarchyEvars = [
      'eVar57',
      'eVar58',
      'eVar59',
      'eVar60',
    ];

    pageNumber = pageNumber ? Number(pageNumber) : 0;

    // client side test
    s_sc.eVar99 = 'cst';
    self._setFacetImpressions(s_sc);
    self._addShopTheLookPrevPageEvent('event606', s_sc);

    if (BC.hierarchy) {
      s_sc.hier1 = BC.hierarchy.join();
    }

    switch (BC.page.taggingId) {
    // search
      case 'search':

        searchTerm = s_sc.getQueryParam('q');

        self._setCommonVariables('PLP Search', 'Search Results', 'PLP', s_sc);
        self._setInstantsearchQuery(s_sc, searchTerm);

        // must come after _setCommonVariables
        products = self._getProductImpressions(searchTerm, pageNumber, s_sc); // [impressions, productIds]

        s_sc.channel = 'Search Results';

        // only track internal search (event8) if there are no page or facet params, unless the facet is from autocomplete
        var hasPageParam = s_sc.getQueryParam('page');
        var hasFacetParam = s_sc.getQueryParam('p');
        var hasAutocompleteParam = s_sc.getQueryParam('s');

        if (!hasPageParam && (!hasFacetParam || (hasFacetParam && hasAutocompleteParam))) {
          s_sc.events = s_sc.apl(s_sc.events, 'event8', ',', 1);
        }

        s_sc.events = products[1].length ? s_sc.events : s_sc.apl(s_sc.events, 'event9', ',', 1);
        s_sc.hier1 = 'Search Results';
        s_sc.list3 = products[0];
        s_sc.eVar1 = searchTerm;

        // must be set before eVar45
        s_sc.prop1 = searchTerm;

        s_sc.eVar45 = products[1] ? s_sc.prop1 + '::' + products[1] : null; // send nothing if empty results
        s_sc.eVar50 = 'Retail Channel';
        s_sc.prop2 = products[1].length ? products[1].length : '0';

        break;

      // category plp
      case 'product-listing':
        displayName = searchTerm;

        if (BC.page.type === 'best-sellers') {
          displayName = displayName.slice(0, displayName.indexOf(' Best Sellers')).trim();
        } else if (BC.page.type === 'new-arrivals') {
          displayName = displayName.slice(0, displayName.indexOf(' New Arrivals')).trim();
        }

        pageName = 'PLP Cat:' + displayName;

        self._setCommonVariables(pageName, 'PLP Cat', 'PLP', s_sc);

        // must come after _setCommonVariables
        products = self._getProductImpressions(displayName, pageNumber, s_sc); // [impressions, productIds]
        s_sc.list3 = products[0];
        s_sc.eVar50 = 'Retail Channel';

        // set hierarchy
        var taxonomyLevels = [
          'P1',
          'C1',
          'C2',
          'C3',
        ];

        if (hierarchySize < taxonomyHierarchyEvars.length) {
          s_sc.eVar65 = taxonomyLevels[hierarchySize];
          s_sc.prop56 = taxonomyLevels[hierarchySize];
          s_sc[taxonomyHierarchyEvars[hierarchySize]] = displayName;

          if (hierarchySize === (taxonomyHierarchyEvars.length - 1)) {
            s_sc.eVar14 = pageName;
          }
        }

        break;

      // onsale plp
      case 'onsale-product-listing':
        displayName = searchTerm.replace('Outlet', '').trim();
        pageName = 'PLP On Sale:' + displayName;

        self._setCommonVariables(pageName, 'PLP On Sale', 'PLP', s_sc);

        // must come after _setCommonVariables
        products = self._getProductImpressions(displayName, pageNumber, s_sc); // [impressions, productIds]
        s_sc.list3 = products[0];
        s_sc.eVar50 = 'Outlet Channel';

        // set hierarchy vars
        if (hierarchySize < taxonomyHierarchyEvars.length) {
          s_sc[taxonomyHierarchyEvars[hierarchySize]] = displayName;

          if (hierarchySize === (taxonomyHierarchyEvars.length - 1)) {
            s_sc.eVar14 = pageName;
          }
        }

        break;

      // rule plp
      case 'rule-based':
        displayName = searchTerm;
        pageName = 'PLP Rule Based:' + displayName;

        self._setCommonVariables(pageName, 'PLP Rule Based', 'PLP', s_sc);

        // must come after _setCommonVariables
        products = self._getProductImpressions(displayName, pageNumber, s_sc); // [impressions, productIds]
        s_sc.list3 = products[0];
        s_sc.eVar14 = pageName; // leaf by default
        s_sc.eVar50 = 'Retail Channel';
        s_sc.eVar59 = pageName; // 3rd level by default
        s_sc.prop13 = 'Brand:PLP Brand';

        break;

      // brand plp
      case 'brand-product-listing':
        var brandName = '';

        if (BC.page.type === 'brand') {
          if (BC.hierarchy && BC.hierarchy.length) {
            displayName = BC.hierarchy[BC.hierarchy.length - 1];
          } else if (BC.brand && BC.brand.displayName) {
            displayName = BC.brand.displayName;
          }
          brandName = searchTerm.slice(0, searchTerm.indexOf(displayName)).trim();
        } else if (BC.page.type === 'best-sellers') {
          displayName = searchTerm.slice(0, searchTerm.indexOf(' Best Sellers')).trim();
        } else if (BC.page.type === 'new-arrivals') {
          displayName = searchTerm.slice(0, searchTerm.indexOf(' New Arrivals')).trim();
        }

        pageName = 'PLP Brand:' + displayName;

        self._setCommonVariables(pageName, 'PLP Brand', 'PLP', s_sc);

        // must come after _setCommonVariables
        products = self._getProductImpressions(displayName, pageNumber, s_sc); // [impressions, productIds]

        s_sc.list3 = products[0];
        s_sc.eVar50 = 'Retail Channel';
        s_sc.eVar59 = pageName; // 3rd level by default
        s_sc.prop7 = brandName;

        BC.hierarchy.forEach(function(category, index) {
          s_sc[taxonomyHierarchyEvars[index]] = category;
        });

        break;

      case 'gear-guide':
        pageName = displayName = 'Gear Guide:' + location.pathname.replace('/', '');

        self._setCommonVariables(pageName, 'Activity Page', 'gearguide', s_sc);
        // must come after _setCommonVariables
        products = self._getProductImpressions(displayName, pageNumber, s_sc); // [impressions, productIds]

        s_sc.list3 = products[0];
        s_sc.eVar14 = pageName; // leaf by default
        s_sc.eVar50 = 'Retail Channel';
        s_sc.eVar59 = pageName; // 3rd level by default
        s_sc.prop13 = 'Activity Page:Gear Guide';

        break;
      // New Arrivals
      case 'new-arrivals':

        displayName = 'New Arrivals';
        pageName = 'New Arrivals';

        self._setCommonVariables(pageName, 'New Arrivals', 'PLP', s_sc);
        // must come after _setCommonVariables
        products = self._getProductImpressions(displayName, pageNumber, s_sc); // [impressions, productIds]

        s_sc.list3 = products[0];
        s_sc.eVar14 = pageName; // leaf by default
        s_sc.eVar50 = 'Retail Channel';
        s_sc.eVar59 = pageName; // 3rd level by default
        s_sc.prop13 = 'New Arrivals:New Arrivals';

        break;
    }
  },

  _setUsersGearhead(s_sc) {
    if (BC.site && BC.site.code === 'bcs') {
      var ghId = BC.gearheadConnection ? BC.gearheadConnection.userId : undefined;

      s_sc.eVar87 = ghId ? 'true|' + ghId : 'false';
    }
  },

  _trackPage_shopAllBrands_full(s_sc) {
    var allBrands = 'All Brands';
    var brand = 'Brand';

    s_sc.pageName = allBrands;
    s_sc.channel = brand;
    s_sc.eVar50 = 'Retail Channel';
    s_sc.prop7 = 'All';
    s_sc.prop11 = allBrands;
    s_sc.prop13 = brand;
    s_sc.eVar15 = allBrands;
    s_sc.eVar36 = allBrands;
  },

  _trackPage_shopTheLook_full() {
    var pageType = 'Shop The Look';
    var adminDisplayName = BC.page && BC.page.adminDisplayName || '';

    this._setContentPageNames(pageType, adminDisplayName);
    this._addOmnitureEntryPageCookie(s_sc);
  },

  _trackPage_static_content_full(s_sc) {
    var STATIC_CONTENT = 'Static Content';
    var isPromoContent = BC.staticContent && BC.staticContent.isPromoContent;
    var displayName = BC.staticContent && BC.staticContent.displayName || '';
    var itemDisplayName = (isPromoContent ? 'Promo' : STATIC_CONTENT) + ': ' + displayName;

    s_sc.channel = STATIC_CONTENT;
    s_sc.prop11 = STATIC_CONTENT;
    s_sc.eVar15 = STATIC_CONTENT;
    s_sc.eVar36 = itemDisplayName;
    s_sc.pageName = itemDisplayName;
    this._addOmnitureEntryPageCookie(s_sc);
  },

  _trackPage_story_full() {
    var pageType = 'Story';
    var adminDisplayName = BC.page && BC.page.adminDisplayName || '';

    this._setContentPageNames(pageType, adminDisplayName);
    this._addOmnitureEntryPageCookie(s_sc);
  },

  _trackPage_promotion_full(s_sc) {
    var pageType = 'Promotion';
    var pageName = BC && BC.promotion && BC.promotion.campaignName || '';

    s_sc.pageName = pageType + ': ' + pageName;
    s_sc.pageType = pageType;
    s_sc.channel = pageType;
    s_sc.eVar15 = pageType;
    s_sc.eVar36 = pageType + ': ' + pageName;
    s_sc.prop11 = pageType;
    this._addOmnitureEntryPageCookie(s_sc);
  },
  // END page tracking methods

  // START helper methods
  _addOmnitureEntryPageCookie(s_sc) {
    var date = new Date();

    date.setTime(date.getTime() + (30 * 60 * 1000));

    var c_value = escape(s_sc.pageType + '^' + s_sc.pageName) +
            ';  path=/; ' +
            ((date == null) ? '' : '; expires=' + date.toUTCString());

    document.cookie = 'OmnitureEntryPage=' + c_value;
  },

  _addShopTheLookPrevPageEvent(evt, s_sc) {
    var shopTheLookSlug = '/shop-the-look/';

    if (evt && this._getReferrer().indexOf(shopTheLookSlug) > -1) {
      s_sc.events = s_sc.apl(s_sc.events, evt, ',', 1);
    }
  },

  _executeMappedMethod(config) {
    var key = config.key;
    var map = config.map;
    var method = this[map[key]];

    if ($.isFunction(method)) {
      method.call(this, config.arg);

      return true;
    } else {
      return false;
    }
  },

  _executeMethodOnMatch(config) {
    var list = config.list;
    var key = config.key;
    var method = config.method;

    for (var i = 0; i < list.length; i++) {
      if (key === list[i] && $.isFunction(method)) {
        method.call(this, config.s_sc);
        break;
      }
    }
  },

  _getFacetsFromPath(path, isSearchPage) {
    if (!path) {
      return;
    }

    var args = path.split('|');
    var facetMap = {};

    if (args && args.length) {
      for (var i = 0; i < args.length; i++) {
        if (args[i] === '') {
          continue;
        }
        var argS = args[i].split(':');
        var key = argS[0];
        var value = argS[1];

        if (key.toLowerCase() === 'category') {
          if (isSearchPage) {
            var categories = value.split('.');
            var categoryName;

            if (categories.length > 2) {
              categories = categories.splice(2);

              for (var x = 0; x < categories.length; x++) {
                categoryName = categories[x];
              }

              facetMap[key] = categoryName;
            }
          }
        } else {
          facetMap[key] = value;
        }
      }
    }

    return facetMap;
  },

  _getProductImpressions(term, pageNumber, s_sc) {
    var productIds = '';
    var impressions = '';

    $('.product').each(function(index) {
      var productId = $(this).attr('data-product-id');

      productIds += ',' + productId;
      impressions += ',' + s_sc.pageType + ':' + term + ':' + (pageNumber + 1) + ':' + (index + 1) + ':' + productId;
    });

    return [impressions.slice(1), productIds.slice(1)];
  },

  _getReferrer() {
    return document.referrer;
  },

  _getTrackingInfo(s_sc) {
    var ti = s_sc.getQueryParam('ti');
    var decodedTi;

    if (ti) {
      decodedTi = atob(ti);
      var lastIndex = decodedTi.lastIndexOf(':');

      if (lastIndex > 0) {
        decodedTi = decodedTi.substring(0, lastIndex);
      }
    }

    return 'Experience:' + ((decodedTi) || ':::');
  },

  _getTrackingInfoClickPosition(s_sc) {
    var ti = s_sc.getQueryParam('ti');
    var clickPosition = -1;

    if (!ti) {
      return clickPosition;
    }

    var decodedTi = atob(ti);
    var lastIndex = decodedTi.lastIndexOf(':');

    if (lastIndex > 0) {
      decodedTi = decodedTi.substring(0, lastIndex); // remove last item
      s_sc.eVar54 = decodedTi + ':' + s_sc.prop5;

      if (BC.search && BC.search.pageSize) {
        var tiArray = decodedTi.split(':', 6);

        clickPosition = ((parseInt(tiArray[2]) - 1) * parseInt(BC.search.pageSize)) + parseInt(tiArray[3]);
      }
    }

    s_sc.events = s_sc.apl(s_sc.events, 'event89', ',', 1);
    s_sc.events = s_sc.apl(s_sc.events, 'event90', ',', 1);

    return clickPosition;
  },

  _registerRecImpressions(widgetType) {
    var $products = $('.js-' + widgetType + ' .product:visible');

    if ($products.length) {
      return 'BC:' + widgetType;
    }

    return undefined;
  },

  _getParameterByName(name, url) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    var results = regex.exec(url);

    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  _setClickedFacets(s_sc) {
    var currentPath = this._getParameterByName('p');
    var isSearchPage = s_sc.pageType && s_sc.pageType.toLowerCase() === 'search results';

    s_sc.products = ';[[internal_search_results]]';

    if (!currentPath) {
      return;
    }

    var currentFacets = this._getFacetsFromPath(currentPath, isSearchPage);

    if (!currentFacets) {
      return;
    }

    var newFacets = [];

    for (var currentFacet in currentFacets) {
      var facetValue = currentFacets[currentFacet];

      newFacets.push(currentFacet + ':' + facetValue);
    }

    if (newFacets.length) {
      var lastFacet = this._facetMapping(newFacets[newFacets.length - 1].replace(/\\/g, ''));

      if (isSearchPage) {
        s_sc.eVar46 = s_sc.pageType + ':' + s_sc.getQueryParam('q') + ':' + lastFacet;
      } else {
        s_sc.eVar46 = s_sc.pageName + ':' + lastFacet;
      }
    }
  },

  _facetMapping(facet) {
    if (facet) {
      var parts = facet.split(':');
      var fqMapper = this.config.filterQueryMapper[parts[0]];

      if (fqMapper) {
        return fqMapper + ':' + parts[1];
      }
    }

    return facet;
  },

  // Remove client side data from query params
  // used when promo ids and params get entagled
  // with client side nav (React Router)
  _removeClientSideNavData(param) {
    return param && param.split('#')[0];
  },

  _setCommunityEvents(s_sc) {
    var $wall = $('.the-wall');
    var widgetEvent = s_sc.getQueryParam('nw');

    if (widgetEvent) {
      s_sc.eVar27 = 'widget: PDP link';
      s_sc.events = s_sc.apl(s_sc.events, 'event20', ',', 1);
    }
    if ($wall.find('.js-pdp-wall-masonry-item.review').length) {
      s_sc.events = s_sc.apl(s_sc.events, 'event380', ',');
    }
    if ($wall.find('.question').length) {
      s_sc.events = s_sc.apl(s_sc.events, 'event381', ',');
    }
  },

  _setCommonVariables(pageName, pageType, pageTemplate, s_sc) {
    s_sc.pageName = pageName;
    s_sc.pageType = pageType;

    s_sc.eVar15 = pageType;
    s_sc.eVar36 = pageName;
    s_sc.eVar64 = pageTemplate;

    s_sc.prop11 = pageType;
    s_sc.prop55 = pageTemplate;

    var date = new Date();

    date.setTime(date.getTime() + (30 * 60 * 1000));

    var c_value = escape(s_sc.pageType + '^' + s_sc.pageName) + ';  path=/; ' +
            ((date == null) ? '' : '; expires=' + date.toUTCString());

    document.cookie = 'OmnitureEntryPage=' + c_value;
  },

  _setCompetitiveCyclistPdpWidgets(s_sc) {
    // check impressions of 'more builds'/'same category' competitive widgets
    if ($('.show-more-builds:visible').length) {
      s_sc.prop59 = 'More Builds' + (s_sc.prop59 ? (',' + s_sc.prop59) : '');
    }
    if ($('.show-same-category:visible').length) {
      s_sc.prop59 = 'Same Category' + (s_sc.prop59 ? (',' + s_sc.prop59) : '');
    }
  },

  _setContentPageNames(pageType, pageName) {
    s_sc.pageName = pageType + ': ' + pageName;
    s_sc.pageType = pageType;
    s_sc.channel = pageType;
    s_sc.eVar15 = pageType;
    s_sc.eVar36 = pageType + ': ' + pageName;
    s_sc.prop11 = pageType;
  },

  _setEntryPage(s_sc) {
    // entry page
    var domainRegex = new RegExp('.*' + BC.site.domain + '.*');

    if (!document.referrer || !domainRegex.test(document.referrer)) {
      s_sc.eVar56 = s_sc.prop11;
      s_sc.eVar44 = s_sc.pageName;
    }
  },

  _setFacetImpressions(s_sc) {
    var globalFacetList = this.config.globalFacetList && this.config.globalFacetList.slice() || [];
    var globalFacets = '';
    var localFacets = '';
    var $facetLabels = $('.facet-wrap[data-fieldname]');

    $facetLabels.each(function() {
      var fieldName = $(this).attr('data-fieldname');

      for (var i = 0; i < globalFacetList.length; i++) {
        if (fieldName.indexOf(globalFacetList[i]) > -1) {
          globalFacets += ',' + fieldName;
          globalFacetList.splice(i, 1);

          return;
        }
      }

      localFacets += ',' + fieldName;
    });

    s_sc.prop57 = globalFacets.slice(1);
    s_sc.prop58 = localFacets.slice(1);
  },

  _setInstantsearch(s_sc) {
    var autoCompleteEvent = s_sc.getQueryParam('s');

    if (autoCompleteEvent) {
      // server side tracking adds 54 only when s=a..automcomplete
      s_sc.events = s_sc.apl(s_sc.events, 'event54', ',', 1);
    }
  },

  _setInstantsearchQuery(s_sc, searchTerm) {
    searchTerm = searchTerm || s_sc.getQueryParam('q');

    if (s_sc.getQueryParam('s') === 'a') {
      s_sc.list1 = searchTerm;
      var pathParam = s_sc.getQueryParam('p');

      if (pathParam && pathParam.indexOf('category') > -1 && pathParam.indexOf('|') === -1) {
        var categoryName = pathParam.split('.' + BC.site.code + '.');

        if (categoryName && categoryName[1]) {
          categoryName = categoryName[1].replace(/\\/g, '');
        }
        if (categoryName) {
          s_sc.list1 += '|' + categoryName;
        }
      }
    }
  },

  _setMarketingCampaigns(s_sc) {
    s_sc.campaign = null;

    var cmp_id = s_sc.getQueryParam('cmp_id');
    var pid = s_sc.getQueryParam('pid');
    var avad = s_sc.getQueryParam('avad');
    var irpid = s_sc.getQueryParam('irpid');

    if (avad) {
      var avantLinkRegex = /(^[^_][^_]*).*/;
      var matches = avantLinkRegex.exec(avad);

      if (matches) {
        if (matches[1] != null) {
          avad = matches[1];
        }
      }
    }

    var jftid = s_sc.getQueryParam('jftid');
    var aid = s_sc.getQueryParam('aid');
    var ganid = s_sc.getQueryParam('ganid');
    var att = s_sc.getQueryParam('att');

    if (pid) {
      s_sc.campaign = 'PID_' + pid;
    } else if (ganid) {
      s_sc.campaign = 'GANID_' + ganid;
    } else if (avad) {
      s_sc.campaign = 'AVAD_' + avad;
    } else if (jftid) {
      s_sc.campaign = 'JFTID_' + jftid;
    } else if (aid) {
      s_sc.campaign = 'AID_' + aid;
    } else if (cmp_id) {
      s_sc.campaign = cmp_id;
    } else if (irpid) {
      s_sc.campaign = 'irpid_' + irpid;
    } else if (att && att.toLowerCase() == 'desktopalert') {
      s_sc.campaign = 'ODAL_DSK5001_v1.4';
    }

    // execute the catch-all code for social media urls on the client.
    // some urls with no query string are cached on akamai.
    // missing this logic on the server
    var socialMedia = /facebook.com|twitter.com|youtube.com|vimeo.com/;

    if (!s_sc.campaign && socialMedia.test(document.referrer)) {
      s_sc.campaign = 'www.' + socialMedia.exec(document.referrer);
    }

    s_sc.eVar18 = s_sc.prop50 = s_sc.campaign;
  },

  _setPlpSorting(s_sc) {
    var impressions = '';

    $('.plp-sorting .js-tabdropdown-dropdown a').each(function(idx, element) {
      var text = $(element).text();
      var parts = text.split(' ');

      if (parts[0] && parts[1]) {
        impressions += impressions ? ',' : '';
        impressions += parts[0].charAt(0) + parts[1].charAt(0) + (idx + 1) + (idx < 3 ? 'S' : 'H');
      }
    });

    if (impressions) {
      s_sc.prop65 = impressions;
    }
  },

  _setProfile(s_sc) {
    var isLoggedIn = s_sc.bcGetCookie('BC_USR', 'loggedIn');
    var isRegistered = s_sc.bcGetCookie('BC_USR', 'registered');
    var loginState = 'Logged out';
    var userId = s_sc.bcGetCookie('BC_USR', 'id');

    if (userId) {
      if (isLoggedIn || isRegistered) {
        s_sc.eVar24 = userId;
        loginState = isLoggedIn ? 'Logged in' : 'Recognized';
      }

      if (isLoggedIn || isRegistered) {
        s_sc.events = s_sc.apl(s_sc.events, 'event101', ',', 1);
      }
    }

    s_sc.prop18 = s_sc.eVar28 = loginState;
  },

  _setPromos(s_sc) {
    // the intermal promo ID is sent in two separate variables that
    // aggregate revenue in different ways
    s_sc.eVar5 = s_sc.eVar93 = this._removeClientSideNavData(s_sc.getQueryParam('int_id'));

    // p13n promos
    var p13nCampaignId = s_sc.getQueryParam('pcmp_id');

    if (s_sc.eVar5 && p13nCampaignId) {
      s_sc.eVar40 = s_sc.eVar5 + '_' + p13nCampaignId;
    }
  },

  _setRecWidgetEvent(s_sc) {
    var recWidgetEvent = s_sc.getQueryParam('rec');

    if (recWidgetEvent === 'best') {
      s_sc.eVar73 = 'Best Sellers | ' + s_sc.prop5;
    } else if (recWidgetEvent === 'new') {
      s_sc.eVar73 = 'New Arrivals | ' + s_sc.prop5;
    } else if (recWidgetEvent === 'moreBuilds') {
      s_sc.eVar64 = 'More Builds';
    } else if (recWidgetEvent === 'sameCat') {
      s_sc.eVar64 = 'Same Category';
    }
  },

  _setRecWidgetImpressions(s_sc) {
    var self = this;
    var props = [];
    var tags = [
      'best-sellers',
      'new-arrivals',
      'p13n-products',
      'bikesByModel',
      'sameCategory',
    ];

    $.each(tags, function(idx, el) {
      var tag = self._registerRecImpressions(el);

      if (tag === 'BC:best-sellers') {
        s_sc.events = s_sc.apl(s_sc.events, 'event87', ',', 1);
      }
      if (tag) {
        props.push(tag);
      }
    });
    if (props.length > 0) {
      s_sc.prop59 = props.join(',');
    }
  },

  _setSeoExperiment(s_sc) {
    if (BC && BC.seo && BC.seo.selectedVariation && BC.seo.experimentName) {
      s_sc.eVar43 = BC.seo.experimentName + ':' + BC.seo.selectedVariation;
    }
  },

  _setSuggestedItem(s_sc) {
    s_sc.eVar4 = s_sc.getQueryParam('rsc_id') || s_sc.getQueryParam('csp_id');
  },

  _setTimestamp(s_sc) {
    var d = new Date();
    var dateTime = d.toDateString().substr(0, 3) + '-MI' + d.getMinutes() + '-H' + d.getHours() + '-D' + d.getDate() + '-M' + (d.getMonth() + 1) + '-Y' + d.getFullYear();

    s_sc.prop25 = dateTime;
  },
  // END helper methods
};

// for backwards compatability during first deployment
BCOmniTrack = function(s_sc) {
  return bcOmniTrack.init(s_sc);
};

BCFullClientSideTracking = function(s_sc) {
  return bcOmniTrack.init(s_sc, {
    fullClientTracking: true,
  });
};
$.publish('omnitrack.loaded');
